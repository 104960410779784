
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import CourseInfoDrawer from '@/layout/header/partials/course/CourseInfoDrawer.vue';
import CourseCopyDrawer from '@/layout/header/partials/course/CourseCopyDrawer.vue';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import axios from 'axios';
import { ElNotification } from 'element-plus';

// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-entity-course',
  components: {
    axios,
    Swal,
    Form,
    Field,
    Datatable,
    CourseInfoDrawer,
    CourseCopyDrawer,
    Multiselect,
  },
  data() {
    return {
      componentKey: 0,
      tranche: [],
      association: [] as any,
      institutes: [] as any,
      courseListData: [],
      optionsTranche: [] as any,
      tranches_info: [] as any,
      courseInfoData: [] as any,
      entityInfos: [],
      TpartnerInfos: [] as any,
      TrancheInfos: [] as any,
      division_data: [] as any,
      districts_data: [] as any,
      optionsTPartner: [] as any,
      instituteList:[] as any,
      btnCheck: false,
       batch: {
        entity_id: [] as any,
        tranche_id: [] as any,
        institute_info_id: '',
        course_id: '',
        division: '',
        district: '',
      },
      course: {
        tranche: '' as any,
        entity_id: '' as any,
        institute_info_id: '',
      } as any,
      courseHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '200px',
        },

        {
          name: 'Code.',
          key: 'code',
          sortable: true,
        },
        {
          name: 'Name',
          key: 'course_name',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Total Target Trainee',
          key: 'total_target_trainee',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Unit Cost',
          key: 'unit_cost',
          sortable: true,
          width: '170px',
        },
        {
          name: 'Phase',
          key: 'tranche',
          sortable: true,
        },
         {
          name: 'Scheme',
          key: 'scheme',
          sortable: true,
        },
         {
          name: 'Output',
          key: 'output',
          sortable: true,
        },
         {
          name: 'DLI',
          key: 'dli',
          sortable: true,
        },
        {
          name: 'Type',
          key: 'course_type',
          sortable: true,
        },
        {
          name: 'Contract',
          key: 'contract_name',
          sortable: true,
        },
        {
          name: 'Term',
          key: 'term',
          sortable: true,
        },
        {
          name: 'Session',
          key: 'session',
          sortable: true,
        },
        {
          name: 'Is Mgt Course ?',
          key: 'isManagementCourse',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Skills Level',
          key: 'skill_level_id',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Stipend Course ?',
          key: 'isStipendCourse',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Tot Course ?',
          key: 'isTotCourse',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Employment Eligible ?',
          key: 'isEmploymentEligible',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Month',
          key: 'month',
          sortable: true,
        },
        {
          name: 'Hour',
          key: 'hour',
          sortable: true,
        },
        {
          name: 'Total Training Days',
          key: 'ttd',
          sortable: true,
          width: '200px',
        },
        
      ],
      courseList: [],
      load: false,
      loading: false,
      showCourseNotice: false,
      actionApprove: false,
      actionEdit: false,
      actionDelete: false,
      adminCheck: false,

      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
      entityIds: [] as any,
    };
  },
  async created() {
    this.emitter.on('course-updated', async () => {
      await this.courseInfoList();
    });
    await this.getAssociation();
    this.checkAdmin();
    await this.actioncheck();
     await this.getTranche();
    await this.getTrainingInstitute();
  },
   watch: {
    "batch.entity_id": {
      handler: "getTrainingInstitute", // Call the method when batch.entity_id changes
      immediate: false, // Set this to false if you want to trigger on initial mount
    },
  },
  methods: {
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == 'Training Institute') {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Approve') {
                this.actionApprove = true;
              }
              if (actions[j].action_name === 'Edit') {
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Delete') {
                this.actionDelete = true;
              }
            }
          }
        }
      }
    },
    checkAdmin() {
      if (VueCookieNext.getCookie('_seip_entity_type') == 1004) {
        let user = VueCookieNext.getCookie('_seip_user');
        console.log(
          user.employee.entity_organogram[0].entitytyperole.role_title
        );
        if (
          user.employee.entity_organogram[0].entitytyperole.role_title ==
          'Admin'
        ) {
          this.adminCheck = true;
        }
      }
    },

    async printPdf() {
      let formData = new FormData();
        formData.set('entity_id', this.batch.entity_id);
      formData.set('tranche', this.batch.tranche_id);
      formData.set('institute_info_id', this.batch.institute_info_id);
      axios
        .post(`${this.VUE_APP_API_URL}/api/course/course_list_print`, formData)
        .then((response) => {
          document.write(response.data);
          window.print();
          //open the new window and write your HTML to it
        });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
       formData.set('entity_id', this.batch.entity_id);
      formData.set('tranche', this.batch.tranche_id);
      formData.set('institute_info_id', this.batch.institute_info_id);

      await ApiService.post('course/course_list_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      formData.set('entity_id', this.batch.entity_id);
      formData.set('tranche', this.batch.tranche_id);
      formData.set('institute_info_id', this.batch.institute_info_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/course/course_list_expot`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Courselist.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
   
    async btnCheckStatus() {
      this.btnCheck = true;
    },
      async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    batchrunning() {
      Swal.fire({
        text: 'Batch Already Running!',
        icon: 'warning',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
     async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
        '?entity_id=' +
        this.batch.entity_id +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.instituteList = response.data.data;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },

    async courseInfoList() {
      let entity_id = this.batch.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = this.batch.institute_info_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }

      if (this.batch.tranche_id.length > 0) {
        this.load = true;
        await ApiService.get(
          'course/list?tranche=' +
            this.batch.tranche_id +
            '&entity_id=' +
            entity_id +
            '&institute_info_id=' +
            institute_info_id
        )
          .then((response) => {
            this.load = false;
            this.courseList = response.data.data;
            console.log(this.courseList);
            this.componentKey += 1;
            this.courseInfoData = true;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Please Select Phase!',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

    async editCourse(data) {
      this.emitter.emit('course-updated-drawer', data);
    },
    async copyCourse(data) {
      this.emitter.emit('course-copy-drawer', data);
    },
    // async courseLog(data) {
    //   await ApiService.get("course/edit/" + data.id)
    //     .then((response) => {
    //       this.load = false;
    //       if (response.data.data) {
    //         this.load = false;
    //         return response.data.data;
    //       }
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('course/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('course-updated', true);

              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
